.cards {
  background-color: var(--color-gray);
  position: relative;


  &:before,
  &:after {
    content: '';
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    background-repeat: no-repeat;
  }

  &:before {
    background-image: url(../images/layout/bg-videos.png);
    background-size: 1920px;
    background-position-y: 80%;
    background-position-x: center;
    padding-top: 60px;
  }

  &:after {
    background-image: url(../images/layout/bg-plate.png);
    background-repeat: no-repeat;
    background-size: 1920px;
    background-position: top 250px center;
  }

  .container {
    padding: 40px 20px 40px;
    position: relative;
    z-index: 2;

    @media screen and (min-width: 768px) {
      padding: 110px 0 150px;
    }
  }

  .h1 {
    margin-bottom: 2rem;

    @media screen and (min-width: 768px) {
      margin-bottom: 5rem;
    }
  }

  ul {
      display: flex;
      flex-wrap: wrap;
      align-items: flex-start;
      justify-content: center;

    li {
      max-width: 440px;
      background-color: var(--color-white);
      padding-bottom: 20px;
      border-radius: 20px;
      overflow: hidden;
      margin: 0 0 20px;
      transition: box-shadow .2s ease-in;

      @media screen and (min-width: 768px) {
        margin: 0 20px 40px;

        align-self: stretch;
      }

      &:hover {
        box-shadow: 0 0 11px 1px rgba(#505050, .3);
      }

      article {
        
      }

      a {
        text-decoration: none;
      }

      img {
        margin-bottom: 30px;
        display: block;
        width: 100%;
        height: 330px;
        object-fit: cover;

        @media screen and (min-width: 768px) {
          line-height: 2.5rem;
          margin-bottom: 17px;
        }
      }

      .h3 {
        padding: 0 20px;
        line-height: 1.3rem;
        margin-bottom: 15px;

        @media screen and (min-width: 768px) {
          line-height: 2.5rem;
          margin-bottom: 17px;
        }
      }

      p {
        font-size: 0.875rem;
        line-height: 1.3rem;
        padding: 0 20px;
        color: var(--color-black);
        text-align: left;

        span {
          font-size: 0.75rem;
          line-height: .95rem;
          display: block;
          margin-top: 5px;
        }
        
        @media screen and (min-width: 768px) {
          font-size: 1.25rem;
          line-height: 2.1875rem;

          span {
            font-size: 0.9rem;
            line-height: 1.2rem;
          }
        }
      }
    }
  }
}