.footer {
  padding: 30px 0;
  @media screen and (min-width:  768px) {
    padding: 60px 0;
  }
  .container {
    max-width: 960px;
    padding: 0 20px;
  }

  background-color: var(--color-green);

  .h2 {
    color: var(--color-white);
    line-height: 2.5rem;
    margin-bottom: 1.875rem;

    @media screen and (max-width:  768px) {
      font-size: 1.375rem;
    }
  }

  p {
    color: var(--color-white);
    font-size: 0.875rem;
    line-height: 1.4rem;
    color: #a3df81;
    text-align: center;
    margin-bottom: 2.5rem;

    @media screen and (min-width:  768px) {
      font-size: 1.125rem;
      line-height: 2.25rem;
    }
  }

  ul {
    display: flex;
    justify-content: center;
    align-items: center;
    li {
      margin: 0 8px;
      a {
        text-decoration: none;
        display: inline-block;
        width: 33px;
        height: 33px;
        background-size: contain;
        background-position: center;
        background-repeat: no-repeat;

        &.social--fb {
          background-image: url("../images/layout/social-fb.svg");
        }

        &.social--ig {
          background-image: url("../images/layout/social-ig.svg");
        }

        &.social--ytb {
          background-image: url("../images/layout/social-ytb.svg");
          width: 34px;
          height: 24px;
        }
      }
    }
  }
}