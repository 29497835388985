.author {
  
  max-width: 890px;
  margin: 0 auto 40px;

  &--inner {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    @media screen and (min-width: 768px) {
      text-align: left;
      flex-direction: row;
    }
  }


  &--image {
    position: relative;
    margin: 0px 0 24px;
    @media screen and (min-width: 768px) {
      margin: 0 50px 0 0;
    }

    &:after {
      content: '';
      display: none;
      position: absolute;
      top: 5px;
      left: -45px;
      width: 180px;
      height: 93px;
      z-index: 1;
      // background-image: url(../images/layout/bg-author.svg);
      // background-size: contain;
      // background-repeat: no-repeat;
      // background-position: center;

      @media screen and (min-width: 768px) {
        top: 10px;
        left: -50px;
        width: 240px;
        height: 125px;
      }
    }

    img {
      position: relative;
      z-index: 2;
      object-fit: cover;
      border-radius: 50%;
      // border: 1px solid var(--color-black);
      width: 100px;
      height: 100px;

      @media screen and (min-width: 768px) {
        width: 180px;
        height: 180px;;
      }
    }
  }

  .h2 {
    font-weight: 700;
    margin-bottom: 14px;
    text-align: center;
    color: var(--color-green);
    font-size: 22px;

    @media screen and (min-width: 768px) {
      margin-bottom: 20px;
      font-size: 3rem;
    }
  }

  p {
    font-size: 14px;
    line-height: 16px;

    @media screen and (min-width: 768px) {
      font-size: 20px;
      line-height: 32px;
    }
  }
}