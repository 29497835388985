.basics {
  background-color: var(--color-darkgray);
  background-image: url(../images/layout/bg-basics.png);
  background-size: 1920px;
  background-position: 50% 50px;
  background-repeat: no-repeat;
  padding-top: 30px;

  position: relative;

  @media screen and (min-width: 768px) {
    padding-top: 60px;
  }
  .container {
    padding-bottom: 60px;

    @media screen and (min-width: 768px) {
      padding-bottom: 120px;
    }

    .h1 {
      margin-bottom: 30px;

      @media screen and (min-width: 768px) {
        margin-bottom: 60px;
      }
    }

    > ul {
      display: grid;
      justify-content: center;
      grid-template-columns: repeat(1, 100%);
      gap: 20px;

      @media screen and (min-width: 400px) {
        grid-template-columns: repeat(2, calc( 50% - 10px));
        gap: 20px;
      }

      @media screen and (min-width: 720px) {
        grid-template-columns: repeat(2, calc( 50% - 10px));
        gap: 20px;
      }

      @media screen and (min-width: 900px) {
        grid-template-columns: repeat(2, 320px);
        gap: 20px;
      }

      @media screen and (min-width: 1440px) {
        gap: 40px;
        grid-template-columns: repeat(4, 320px);
      }
      

      > li {
        border-radius: 10px;
        background-color: var(--color-white);
        padding: 30px 15px 20px;
        transition: box-shadow .2s ease-in;
        text-align: center;

        article {
          height: 100%;
          display: flex;
          flex-direction: column;
        }

        .btn {
          margin-top: auto;
        }

        @media screen and (min-width: 768px) {
          padding: 60px 50px 50px;
        }

        &:hover {
          box-shadow: 0 0 11px 1px rgba(#505050, .3);
        }

        .h2 {
          margin-bottom: 30px;
          line-height: 2.5rem;

          @media screen and (max-width: 768px) {
            font-size: 1.225rem;
            line-height: 1.4rem;
            margin-bottom: 15px;
          }
        }

        p {
          font-size: 0.875rem;
          line-height: 1.2rem;
          color: var(--color-black);
          margin-bottom: 20px;

          @media screen and (min-width: 768px) {
            text-align: center;
          }

          &.text--left {
            text-align: left;
          }
          
          @media screen and (min-width: 768px) {
            margin-bottom: 30px;
            font-size: 1.175rem;
            line-height: 1.8rem;
          }
        }

        
        background-position: bottom right;
        background-repeat: no-repeat;
        background-size: 30%;

        @media screen and (min-width: 400px) {
          background-size: 50%;
        }
        @media screen and (min-width: 768px) {
          background-size: auto;
        }

        &:nth-of-type(1) {
          background-image: url('../images/layout/element-1.svg');  
          background-size: 50%;

          @media screen and (min-width: 400px) {
            background-size: 80%;
          }

          @media screen and (min-width: 768px) {
            background-size: auto;
          }
        }

        &:nth-of-type(2) {
          background-image: url('../images/layout/element-2.svg');
        }

        &:nth-of-type(3) {
          background-image: url('../images/layout/element-3.svg');
        }

        &:nth-of-type(4) {
          background-image: url('../images/layout/element-4.svg');
        }
      }
    }
  }

  &--popup {
    position: fixed;
    top: 0;
    left: 0;
    height: 100%;
    width: 100vw;
    padding: 40px 20px 40px 20px;
    min-height: 100vh;
    background: rgba(#000, .3);
    z-index: 10000;         
    overflow: auto;
    
    @media screen and (min-width: 1470px) {
      padding: 80px 40px 80px 20px;
      width: 100vw;
    }

    &-inner {
        max-width: 1400px;
        margin: 0 auto;
        position: relative;
        background-color: #fff;
        border-radius: 10px;
        padding: 20px;

        @media screen and (min-width: 768px) {
          padding: 40px 20px;
          border-radius: 25px;
        }

        @media screen and (min-width: 1470px) {
          padding: 60px 80px;
        }
    }

    &-close {
      content: '';
      display: inline-block;
      position: absolute;
      color: var(--color-black);
      cursor: pointer;
      font-size: 25px;
      top: 20px;
      right: 20px;
      
      @media screen and (min-width: 1470px) {
        
        top: 30px;
        right: 30px;
      }

      @media screen and (min-width: 1470px) {
        font-size: 40px;
      }
    }

    &-extra {
      font-weight: 600;
      display: flex;
      justify-content: center;
      padding: 20px 0;
      
      @media screen and (min-width: 768px) {      
        text-align: center;
        align-items: center;
        background-image: url('../images/layout/bg-extra.png');
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;
        min-height: 202px;
        padding: 0 80px;
        margin: 20px 0 40px !important;
      }
      
      @media screen and (min-width: 1200px) {
        margin: 60px 0 !important;
        padding: 0 200px;
      }
    }

    &-cols {
      @media screen and (min-width: 768px) {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        gap: 20px;
        margin-bottom: 40px;
      }

      @media screen and (min-width: 1470px) {
        grid-template-columns: 1fr 1fr;
        gap: 40px;
        margin-top: 40px;
      }

      > div {
        background-color: #a4df81;
        padding: 20px;
        margin-bottom: 20px;
        border-radius: 20px;

        p {
          text-align: center !important;
        }

        li {
          color: var(--color-black);
        }

        li, p {
          font-weight: 700;
        }

        > div,p  {
          max-width: 340px;
          margin: 0 auto;
        }
        
        &:first-of-type {
          margin-top: 15px;

          @media screen and (min-width: 768px) {
            margin-top: 0;
          }
        }

        &.basics--popup-flex {
          > span {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
          }
        }
        
        @media screen and (min-width: 768px) {
          display: flex;
          justify-content: center;
          align-items: center;
          flex-direction: column;
          background-image: url('../images/layout/popup_bg.png');
          background-position: center;
          background-size: auto 100%;
          background-repeat: no-repeat;
          background-color: transparent;
          margin-bottom: 0;
          padding: 40px 20px ;
        }
        
        @media screen and (min-width: 1470px) {
          padding: 60px 130px;
          background-size: contain;
        }

        p {
          margin-bottom: 0 !important ;
        }

        > span {
          font-size: 1rem;
          font-weight: 700;
          color: var(--color-black);
          
          @media screen and (min-width: 768px) {
            font-size: 1.2rem;
          }

          + span {
            padding-top: 1rem;
          }
        }

        > div > span {
          display: block;
          text-align: center;
          font-size: 0.875rem;
          line-height: 1.2rem;
          font-weight: 700;
          color: var(--color-black);

          &:first-of-type {
            font-size: 0.875rem;
            margin-bottom: .8rem;
          }

          @media screen and (min-width: 768px) {
            font-size: 1rem;
            line-height: 1.2rem;
            &:first-of-type {
              font-size: 1.125rem;
              margin-bottom: .8rem;
            }
          }
        }

        > ul {
          padding-top: 10px;
          text-align: left;

          li {
            margin-bottom: .4rem;
            display: flex;
            align-items: center;
            justify-content: flex-start;
            font-size: 0.875rem;
            
            @media screen and (min-width: 768px) {
              font-size: 1.125rem;
            }

            &:last-of-type {
              margin-bottom: 0;
            }
            &:before {
              content: '';
              display: inline-block;
              width: 10px;
              height: 6px;
              background-image: url('../images/layout/list-mark.svg');
              background-position: center;
              background-size: contain;
              background-repeat: no-repeat;
              margin-right: 10px;
              margin-left: 10px;

              @media screen and (min-width: 768px) {
                width: 14px;
                height: 8px;
              }
            }
          }
        }
      }

      &.basics--popup-cols-3 {
        grid-template-columns: 1fr 1fr 1fr;
        max-width: 100%;
        & > div {
          box-sizing: border-box;
          
          @media screen and (min-width: 768px) {
            background-image: url('../images/layout/popup_bg-3.png');
          }
        }
      }

      img {
        max-width: 100%;
        margin: 0 auto 20px;
        height: auto;
        display: block;
      }
    }
    
    &-content {
        text-align: left;
     
        .btn {
          position: relative;
          left: 50%;
          margin-top: 10px !important;
          transform: translateX(-50%);
          display: inline-block;

          @media screen and (min-width: 768px) {
            margin-top: 40px !important;
          }
        }

        .h2,.h3, p:not(.basics--popup-extra) {
            text-align: left; 
        }

        .h2 {
          @media screen and (min-width: 768px) {
            font-size: 2.25rem;
          }
        }

        .h3 {
          font-size: 1rem;
          margin-bottom: 10px;
          
          @media screen and (min-width: 768px) {
            font-size: 1.6rem;
            margin-bottom: 20px;
            }
        }
    }
  }
}

.App {
  overflow: hidden;
}