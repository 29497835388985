.intro {
  background-color: var(--color-gray);
  .container {
    max-width: 1060px;
  }

  .h1 {
    margin-bottom: 20px;
    

    @media screen and (min-width: 768px) {
      margin-bottom: 40px;
      font-size: 4.7rem;
    }
  }

  .h2 {
    font-size: 1rem;
    line-height: 1.2rem;
    margin-bottom: 15px;
    color: #050505;

    @media screen and (min-width: 768px) {
      font-size: 1.875rem;
      line-height: 2.5rem;
    }
  }

  p {
    text-align: center;
    color: var(--color-black);
    font-size: 0.875rem;
    line-height: 1.125rem;

    @media screen and (min-width: 768px) {
      font-size: 1.375rem;
      line-height: 2.1875rem;
    }
  }

  &--logo {
    display: block;
    max-width: 146px;
    // margin: -45px auto 15px;
    margin: 0px auto 15px;
    position: relative;
    padding-top: 20px;

    @media screen and (min-width: 768px) {
      max-width: 346px;
      // margin: -100px auto 36px;
      margin: 0 auto 36px;
      padding-top: 50px;
    }
  }
}