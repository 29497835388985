.banner {
  background-color: var( --color-green );  
  position: relative;
  
  .container {
    max-width: 1920px;

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 0 20px 70px;

    @media screen and (min-width: 768px){
      display: grid;
      justify-content: center;
      grid-template-columns: repeat(2, 380px);
      padding: 0 20px;
    }

    @media screen and (min-width: 900px){
      display: grid;
      justify-content: center;
      grid-template-columns: 465px 430px;
      padding: 0 20px;
    }

    @media screen and (min-width: 1140px){

      grid-template-columns: repeat(3, 380px);
    }

    @media screen and (min-width: 1400px){
      grid-template-columns: 420px 540px 400px;
    }

    @media screen and (min-width: 1920px){
      justify-content: flex-start;
      grid-template-columns: 640px 630px 510px;
      
    }
  }

  &--inner {
    text-align: center;

    .h2 {

      font-size: 2rem;
      line-height: 2.4rem;
      padding-top: 20px;
      color: var(--color-white);
      margin: 0 auto 20px;
      padding-top: 20px;

      font-family: astounder-squared-bb, sans-serif;
      font-style: normal;
      font-weight: 400;

      max-width: 400px;
      
      span {
        font-family: astounder-squared-bb, sans-serif;
        font-style: normal;
        font-weight: 400;
        color: var(--color-yellow);
      }
      
      @media screen and (min-width: 768px) {
        font-size: 3rem;
        line-height: 3.4375rem;
        padding-top: 100px;
        max-width: 100%;
      }

      @media screen and (min-width: 1140px) {
        font-size: 3.15rem;
        line-height: 3.5rem;
      }

      @media screen and (min-width: 1400px) {
        font-size: 3.625rem;
        line-height: 3.875rem;
      }
    }

    p {
      font-size: 0.875rem;
      line-height: 1.3rem;
      color: var(--color-white);
      
      font-weight: 400;
      padding-bottom: 20px;

      @media screen and (min-width: 768px) {
        padding-bottom: 110px;
        font-size: 1.25rem;
        line-height: 2rem;
      }
    }

    max-width: 700px;
    margin: 0 auto;
  }

  &--image {
    margin-bottom: -30px;

    @media screen and (max-width: 1140px){
      display: none;
    }

    img {
      max-width: 100%;
    }
  }
}