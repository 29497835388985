@import '../node_modules/video-react/styles/scss/video-react.scss';

.video {
  text-align: center;
  padding-bottom: 30px;
  background-color: var(--color-darkgray);
  position: relative;

  .container {
    position: relative;
    z-index: 1;
  }

  @media screen and (min-width: 768px) {
    padding-bottom: 60px;
  }

  &:after {
    content: '';
    width: 100%;
    height: 110%;
    top: 50px;
    left: 0;
    position: absolute;
    background-image: url(../images/layout/bg-videos.png);
    background-repeat: no-repeat;
    background-size: 2000px;
    background-position-y: 0;
    background-position-x: 50%;
    pointer-events: none;
  }

  &-overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 5;

    &:after {
      content: '';
      width: 80px;
      height: 80px;

      background-position: center;
      background-repeat: no-repeat;
      background-image: url(../images/layout/play-button.svg);
      
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }

    img {
      height: 100%;
      width: 100%;
      object-fit: cover;
    }
  }

  &-container {
    position: relative;
    padding-bottom: 177.777778%; /* 16:9 */
    height: 0;
    cursor: pointer;

    border-radius: 10px;
    overflow: hidden;

    iframe, video {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;

    }
  }
  
  ul {
    display: grid;
    grid-template-columns: repeat(1, calc( 100% ));
    gap: 20px;
    justify-content: center;

    li {
      &:nth-of-type(2) {
        .title--short {
          &:after {
            transform: rotate(12deg);
          }
        }
      }
      &:nth-of-type(3) {
        .title--short {
          &:after {
            transform: rotateX(171deg) rotate(194deg);
          }
        }
      }
      &:nth-of-type(4) {
        .title--short {
          &:after {
            transform: rotate(190deg);
          }
        }
      }
    }

    @media screen and (min-width: 600px) {
      grid-template-columns: repeat(2, calc(50% - 10px));
      gap: 20px;
      margin-bottom: 70px;
    }

    @media screen and (min-width: 900px) {
      grid-template-columns: repeat(2, 320px);
      gap: 40px;
      margin-bottom: 70px;
    }

    @media screen and (min-width: 1440px) {
      grid-template-columns: repeat(4, 320px);
      gap: 40px;
      margin-bottom: 70px;
    }
  }
}


.video-react .video-react-poster {
  background-size: cover;
}

.video-container .video-react .video-react-big-play-button {
  border-radius: 50%;
  background-color: transparent;
  border: 5px solid var(--color-white);
  width: 75px;
  height: 75px;
  display: flex;
  justify-content: center;
  align-items: center;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);

  &:before {
    font-size: 60px;
    width: 70px;
    height: 40px;
    position: relative;
  }
}

.video-react .video-react-big-play-button.big-play-button-hide {
  display: none;
}