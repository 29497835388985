.plate {
  position: relative;
  padding: 50px 0;
  background-color: var(--color-gray);

  svg {
    pointer-events: none;
    path {
      cursor: pointer;
      pointer-events: all;
    }
  }

  .container {
    padding: 0;
  }

  &:after {
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    height: 70%;
    width: 100%;
    background-color: var(--color-darkgray);
    z-index: 1;
    background-image: url(../images/layout/bg-plate.png);
    background-repeat: no-repeat;
    background-size: 1920px;
    background-position: top 250px center;
  }

  &--inner {
    position: relative;
    z-index: 2;
  }

  &--arrow {
    position: absolute;
    width: 80px;
    left: 40px;
    top: 20px;

    img {
      width: 30px;
      margin-top: 10px;
      margin-left: 25px;
    }

    @media screen and (min-width: 768px) {
      display: none;
    }
  }

  &--wrapper {
    position: relative;

   
    .hidden {
      opacity: 0;
    }

    img {
      width: 100%;
      pointer-events: none;
    }
  }

  &--overlay {
    // display: none;
    position: absolute;
    top: 0;
    left: 0px;
    width: 100%;
    height: 100%;
    z-index: 15;
    transform: scale(0.9);
    

    .plate-group {
      fill: transparent;
      cursor: pointer;
    }

    .plate-group:hxover {
      transition: transform .3s ease-in;
      transform-origin: center center;
      transform: scale(1.05);
      z-index: 10;
      fill: transparent;
      box-shadow: inset 5em 1em gold;
    }
  }

  &--placeholder {
    position: relative;
  }

  &--bg {
    position: relative;
    max-width: 975px;
    left: 50%;
    transform: translateX(-50%);
  }

  &--bg-x {
    position: absolute;
    left: 0;
    width: 100%;
    height: 100%;
    display: none;
    
    @media screen and (min-width: 1200px) {
      top: 120px;
      display: block;
    }
  }

  &--clear {
    position: absolute;
    top: 0;
    left: 0;
    max-width: 975px;
    left: 50%;
    transform: translateX(-50%);
    &.opened {
      animation: hideClear .3s forwards ease-in;
    }
  }

  &--part {
    opacity: 0;
    position: absolute;
    top: 0;
    left: 0;
    transition: all .3s ease-in;

    img {
      @media screen and (min-width: 1200px) {
        width: auto;
      }
    }

    @media screen and (min-width: 1200px) {
      left: 50%;
      transform: translateX(-50%);
    }

    .plate--popup {
      display: none;
    }

    &.show {
      
      filter: drop-shadow(0 0 12px #fff);
      z-index: 100;
      pointer-events: none;
      opacity: 1;
      transform: scale(1.1);
      z-index: 9999;
      @media screen and (min-width: 1200px) {
        transform: translateX(-50%) scale(1.1);
      }

      .plate--popup {
        display: flex !important;
        flex-direction: column;
      }
    }

    &.hovered {
      transform-origin: center;
      filter: drop-shadow(0 0 12px #fff);
      z-index: 100;
      pointer-events: none;
      opacity: 1;
      transition: all .3s ease-in forwards;
      transform: scale(1.1);
      @media screen and (min-width: 1200px) {
        transform: translateX(-50%) scale(1.1);
      }
    }
  }


  &--popup {
    background-color: var(--color-green);
    border-radius: 20px;
    position: absolute;
    top: -120px;
    right: 50px;
    width: 480px;
    padding: 30px 0 20px;
    color: var(--color-white);
    z-index: 10;
    pointer-events: all;

    &-overlay {
      width: 100vw;
      height: 100vh;
      background-color: transparent;
      position: fixed;
      top: 0;
      left: 0;
    }
    
    .h2 {
      font-size: 1.4rem;
      line-height: 1.8rem;
      color: var(--color-white);
      text-align: left;
      margin-bottom: 16px;
      padding: 0 30px;
    }

    .popup-list {
      list-style: disc;
      padding-top: 10px;
      padding-left: 20px;
      li {
        font-size: 16px;
        margin-bottom: 6px;
      }
    }

    p {
      font-size: 1rem;
      line-height: 1.4rem;
      font-weight: 300;
      padding: 0 30px;

      span.p-label {
        display: block;
        font-size: 18px;
        font-weight: 600;
        text-align: left;
        color: var(--color-white);
        margin: 10px 0 6px;

      }

      &.plate--popup-more {
        background-image: url(../images/layout/bg-popup.png);
        background-size: 90%;
        background-repeat: no-repeat;
        background-position: center;
        min-height: 145px;
        font-size: 1rem;
        line-height: 1.4rem;
        color: var(--color-black);
        font-weight: 600;
        padding: 0 58px 0 72px;
        margin-top: 0px;
        display: flex;
        justify-content: center;
        align-items: center;
        text-align: center;
      }
    }

    &-controls {
      display: flex;
      justify-content: space-between;
      margin-top: auto;
    }

    &-prev {
      display: flex;
      flex-direction: row-reverse;
      align-items: center;
      justify-content: center;

      &:after {
        transform: rotate(180deg);
        margin-left: 0 !important;
        margin-right: 10px;
      }
    }

    &-box {
      background-color: #3B890E;
      -webkit-mask-image: url(../images/layout/bg-plate-box.svg);
      mask-image: url(../images/layout/bg-plate-box.svg);
      mask-repeat: no-repeat;
      -webkit-mask-repeat: no-repeat;
      mask-size: 100%;
      -webkit-mask-size: 100%;
      padding: 0 60px;
      width: calc( 100% - 40px );
      margin: 20px auto;
      height: 190px;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;

      span {
        font-size: 16px;
        line-height: 1rem;
        font-weight: 600;
        display: block;
        text-align: center;
        margin-bottom: 6px;
      }

      ul {
        li {
          list-style: none;
        }
      }

      p {
        font-size: 14px;
        line-height: 20px;
        text-align: center;

        + span {
          margin-top: 20px;
        }
      }
    }

    &-next {
      margin-left: auto;
    }
    &-next,
    &-prev {
      color: var(--color-yellow);
      text-align: right;
      padding: 20px 30px 0;
      font-size: 18px;
      font-weight: 600;
      cursor: pointer;

      &:after {
        content: '';
        display: inline-block;
        margin-left: 10px;
        width: 10px;
        height: 12px;
        background-color: var(--color-yellow);
        -webkit-mask-image: url(../images/layout/chevron.svg);
        mask-image: url(../images/layout/chevron.svg);
        mask-repeat: no-repeat;
        -webkit-mask-repeat: no-repeat;
      }
    }
  }
}

@keyframes showPart {
  0% { opacity: 0;}
  100% { 
      opacity: 1;
      transform: translateX(-50%) scale(1.1);
    }
}

@keyframes hideClear {
  0% {opacity: 1;}
  100% { opacity: .5;}
}

.part-triangle{fill:#F76969;stroke:#000000;stroke-miterlimit:10;opacity: 0; cursor: pointer;}

.plate--part {
  .plate--popup {
    &:after {
      content: '';
      position: absolute;
      left: 0;
      top: 30px;
      width: 80px;
      height: 80px;
      z-index: -1;
      transform: rotate(45deg);
      background-color: var(--color-green);
    }

    &-close {
      position: absolute;
      top: 20px;
      right: 20px;
      cursor: pointer;
      font-size: 20px;
      font-weight: 700;
    }
  }
  &-1 {
    .plate--popup {
      right: 300px;
      top: 300px;
      min-height: 560px;
      &:after {
        top: 0px;
        left: auto;
        right: 40px;
      }
    }
  }

  &-2 {
    .plate--popup {
      min-height: 645px;
      top: 200px;
      right: 80px;
    }    
  }

  &-3 {
    .plate--popup {
      top: 180px;
      left: 160px;
      min-height: 620px;

      &:after {
        top: 170px;
        right: 0px;
        left: auto;
      }
    }
  }

  &-4 {
    .plate--popup {
      top: 300px;
      right: 150px;
      min-height: 612px;

      &:after {
        top: 110px;
        left: 0px;
      }
    }
  }

  &-5 {
    .plate--popup {
      top: 350px;
      left: 170px;
      right: auto;
      min-height: 575px;
      &:after {
        top: 170px;
        bottom: auto;
        right: 0px;
        left: auto;
      }
    }
  }

  &-6 {
    .plate--popup {
      top: 360px;
      right: 110px;
      min-height: 505px;

      &::after {
        top: 195px;
      }
    }
  }

  &-7 {
    .plate--popup {
      top: 400px;
      right: auto;
      left: 60px;
      min-height: 580px;

      &:after {
        left: auto;
        right: 0;
        top: 130px;
      }
    }
  }

  &-8 {
    .plate--popup {
      top: 250px;
      right: -50px;
      min-height: 600px;
      &:after {
        top: auto;
        bottom: 40px;
        left: 0;
      }
    }
  }

  &-9 {
    .plate--popup {
      top: 400px;
      right: 140px;
      min-height: 570px;

      &::after {
        top: 170px;
      }
    }
  }
}

.plate--popup-dots {
  display: flex;
  align-items: center;
  justify-content: center;
  padding-top: 10px;
}

.plate--popup-dot {
  content: '';
  width: 10px;
  height: 10px;
  background-color: rgba(#f3f3f3, .5);
  border-radius: 50%;
  margin: 0 5px;
  display: inline-block;

  &.active {
    background-color: #f3f3f3;
  }
}

@media screen and (min-width: 768px) and (max-width: 1200px) {
  .plate--part.show .plate--popup {
    position: fixed;
    top: 0;
    left: 50%;
    transform: translateX(-50%);
    max-width: 640px;
    width: 100%;
    // pointer-events: none;
  }

  .plate--part .plate--popup:after {
    display: none;
  }
}

@media screen and (max-width: 768px) {
  .plate {
    padding: 100px 20px 80px;
  }

  .plate--part .plate--popup {
    min-height: auto !important;
  }

  .plate--part.show .plate--popup {
    position: fixed;
    top: 0;
    left: 0;
    max-width: 640px;
    width: 100%;
    width: calc(100% - 40px);
    left: 50%;
    transform: translateX(-50%);
    // pointer-events: none;

    
    .h2 {
      font-size: 1.1rem;
      line-height: 1.4rem;
      padding: 0 20px;
    }

    p {
      font-size: .9rem;
      line-height: 1.3rem;
      padding: 0 20px;
      margin-bottom: 10px;
    }

    .plate--popup-controls {
      pointer-events: all;
      margin-top: 0;
    }
  }

  .plate--part .plate--popup-close {
    top: 10px;
    right: 15px;
  }
  
  .plate--part .plate--popup:after {
    display: none;
  }

  .plate--part.show .plate--popup .plate--popup-more {
    background-image: none;
    border-radius: 10px;
    font-weight: 700;
    width: calc( 100% - 40px);
    margin: 0 auto 20px;
    background-color: var(--color-yellow);

    &+.plate--popup-box {
      margin-top: 0;
    }
  }

  .plate--popup-box {
    mask-image: none;
    -webkit-mask-image: none;
    border-radius: 10px;
    padding: 20px;
    height: auto;

    p {
      padding: 0;
      font-weight: 700;
    }
  }
}

@media screen and (min-width: 1020px) and (max-width: 1200px) {
  .plate--part {
    left: 50%;
    top: -20px;
    width: 1020px;  
    transform: translateX(-50%);

    &.hovered,
    &.show {
      left: 50%;
      transform: translateX(-50%) scale(1.1);
    }
  }
}