/* http://meyerweb.com/eric/tools/css/reset/ 
   v2.0 | 20110126
   License: none (public domain)
*/

html, body, div, span, applet, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
a, abbr, acronym, address, big, cite, code,
del, dfn, em, img, ins, kbd, q, s, samp,
small, strike, strong, sub, sup, tt, var,
b, u, i, center,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, embed, 
figure, figcaption, footer, header, hgroup, 
menu, nav, output, ruby, section, summary,
time, mark, audio, video {
	margin: 0;
	padding: 0;
	border: 0;
	font-size: 100%;
	font: inherit;
	vertical-align: baseline;
}
/* HTML5 display-role reset for older browsers */
article, aside, details, figcaption, figure, 
footer, header, hgroup, menu, nav, section {
	display: block;
}
body {
	line-height: 1;
}
ol, ul {
	list-style: none;
}
blockquote, q {
	quotes: none;
}
blockquote:before, blockquote:after,
q:before, q:after {
	content: '';
	content: none;
}
table {
	border-collapse: collapse;
	border-spacing: 0;
}

strong {
  font-weight: bold;
}

$font-path: "../fonts/";

@font-face {
  font-family: 'Montserrat';
  src: url('#{$font-path}Montserrat-Bold.woff2') format('woff2'),
      url('#{$font-path}Montserrat-Bold.woff') format('woff');
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Montserrat';
  src: url('#{$font-path}Montserrat-Light.woff2') format('woff2'),
      url('#{$font-path}Montserrat-Light.woff') format('woff');
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Montserrat';
  src: url('#{$font-path}Montserrat-Regular.woff2') format('woff2'),
      url('#{$font-path}Montserrat-Regular.woff') format('woff');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Montserrat';
  src: url('#{$font-path}Montserrat-SemiBold.woff2') format('woff2'),
      url('#{$font-path}Montserrat-SemiBold.woff') format('woff');
  font-weight: 600;
  font-style: normal;
  font-display: swap;
}


body * {
  font-family: 'Montserrat', sans-serif;
  box-sizing: border-box;
}

body {
  font-size: 16px;
}

// colors
$shadow-green: rgba(#4aa716, .5);
$shadow-yellow: rgba(#ffc800, .5);


:root {
  --color-green: #4aa716;
  --color-white: #fdfdfd;
  --color-black: #505050;
  --color-gray: #f3f3f3;
  --color-darkgray: #e8e8e8;
  --color-yellow: #ffe800;
}

.container {
  max-width: 1440px;
  padding: 0 20px;
  margin: 0 auto;
}


.h1 {
  
  font-size: 1.375rem;
  line-height: 1.6875rem;
  text-align: center;
  letter-spacing: 0;
  color: var(--color-green);
  font-weight: 700;
  
  @media screen and (min-width: 768px) {
    font-size: 5rem;
    line-height: 5.625rem;
  }
}

.h2 {
  font-weight: 600;
  color: var(--color-green);
  text-align: center;
  font-size: 1rem;
  line-height: 1.375rem;
  
  @media screen and (min-width: 768px) {
    font-size: 2.5rem;
    line-height: 5.625rem;
  }
}

.h3 {
  font-size: 1.125rem;
  color: var(--color-green);
  line-height: 1.15rem;
  font-weight: 700;
  text-align: center;

  @media screen and (min-width: 768px) {
    font-size: 1.875rem;
  }
}

.btn {
  display: inline-block;
  text-align: center;
  font-weight: 600;
  border-radius: 60px;
  text-decoration: none;
  cursor: pointer;

  &--yellow {
    padding: 25px 52px;
    font-size: 0.875rem;
    color: var(--color-black);
    background: var(--color-yellow);
    background: linear-gradient(90deg, rgba(255,237,0,1) 0%, rgba(255,222,0,1) 35%);
    margin-top: 20px;
    // box-shadow: 0 10px 25px 0 $shadow-yellow;

    @media screen and (min-width: 768px) {
      padding: 36px 52px;
      font-size: 1.375rem;
      margin-top: 0;
    }
  }

  &--green {
    padding: 15px 15px;
    font-size: 0.875rem;
    line-height: 0.875rem;
    color: var(--color-white);
    background: var(--color-green);
    background: linear-gradient(90deg, rgba(101,174,42,1) 0%, rgba(46,164,52,1) 35%);
    box-shadow: 0 10px 10px 0 $shadow-green;

    @media screen and (min-width: 768px) {
      padding: 24px 30px;
      font-size: 1.25rem;
      line-height: 1.25rem;
    }
    
  }

  &--banner {
    position: relative;
    span {
      z-index: 2;
      @extend .btn;
      font-size: 0.875rem;
      padding: 20px 40px;
      color: var(--color-black);
      background: var(--color-yellow);
      background: linear-gradient(90deg, rgba(255,237,0,1) 0%, rgba(255,222,0,1) 35%);
      box-shadow: 0 10px 25px $shadow-yellow;
      max-width: max-content;
      position: relative;

      &:after {
        content: '';
        display: inline-block;
        margin-left: 10px;
        width: 6px;
        height: 10px;
        background-image: url(../images/layout/chevron.svg);
        background-repeat: no-repeat;
        background-position: center;
      }
    }

    &:before {
      content: '';
      z-index: 1;
      position: absolute;
      display: inline-block;
      background-image: url(../images/layout/banner-cart.png);
      background-repeat: no-repeat;
      background-position: center;
    }
  
    @media screen and (min-width: 768px) {
      span {
        font-size: 1.625rem;
        padding: 40px 60px;
        
        &:after {
          width: 10px;
          height: 16px;
          margin-left: 20px;
        }
      }
    }

    @media screen and (min-width: 768px) {
      padding-top: 165px;
      &:before {
        background-image: url(../images/layout/banner-cart-2.png);
        top: 10px;
        left: -20px;
        margin-left: 10px;
        width: 390px;
        height: 230px;
        background-size: contain;
      }
    }

    @media screen and (min-width: 900px) {
      padding-top: 165px;
      &:before {
        background-image: url(../images/layout/banner-cart-2.png);
        top: 10px;
        left: -20px;
        margin-left: 10px;
        width: 390px;
        height: 230px;
        background-size: contain;
      }
    }

    @media screen and (min-width: 1140px) {
      &:before {
        left: -50px;
      }
    }

    @media screen and (min-width: 1400px) {
      padding-top: 165px;
      
      &:before {
        background-image: url(../images/layout/banner-cart.png);
        top: 30px;
        left: -65px;
        width: 380px;
        height: 210px;
      }
    }

    @media screen and (min-width: 1920px) {
      padding-left: 120px;
      &:before {
        top: 10px;
        left: 0;
        width: 460px;
        height: 250px;
      }
    }

  }
}

.title--short {

  font-weight: 700;
  color: var(--color-white);

  font-size: 0.875rem;
  line-height: 1rem;
  margin-bottom: 23px;
  width: 240px;
  padding: 0 40px;
  margin: 0 auto 23px;

  height: 100px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;

  &:after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: var(--color-green);
    -webkit-mask-image: url(../images/layout/bg-text-short.svg);
    mask-image: url(../images/layout/bg-text-short.svg);
    mask-repeat: no-repeat;
    -webkit-mask-repeat: no-repeat;
    -webkit-mask-size: contain;
    mask-size: contain;
    z-index: -1;
  }

  @media screen and (min-width: 768px) {
    font-size: 1.375rem;
    line-height: 1.625rem;
    margin-bottom: 36px;
    width: 320px;
    height: 133px;
  }
}


@import "banner.scss";
@import "intro.scss";
@import "plate.scss";
@import "video.scss";
@import "basics.scss";
@import "cards.scss";
@import "footer.scss";
@import "author.scss";
